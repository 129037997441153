import PromotionModel from "../../models/Promotion"

export interface promotionStateInterface {
  promotionCode: string
  referralId: string
  promotions: PromotionModel[]
}

const initState: promotionStateInterface = {
  promotionCode: "",
  referralId: "",
  promotions: [],
}

const promotionReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_PROMO_CODE":
      return {
        ...state,
        promotionCode: action.payload.promotionCode,
      }
    case "UPDATE_REFERRAL_ID":
      return {
        ...state,
        referralId: action.payload.referralId,
      }
    case "UPDATE_PROMO_LIST":
      return {
        ...state,
        promotions: action.payload.promotions,
      }
    default:
      return state
  }
}

export default promotionReducer
