import { ScheduleModel } from "../../models/Schedule"

export interface scheduleStateInterface {
  schedules: ScheduleModel[]
  pageIndex: number
  lastCursor: string | Date
}

const initState: scheduleStateInterface = {
  schedules: [],
  pageIndex: 0,
  lastCursor: "",
}

const scheduleReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_SCHEDULE":
      return {
        ...state,
        schedules: action.payload.schedules,
        lastCursor: action.payload.lastCursor,
      }
    case "UPDATE_SCHEDULES_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      }
    case "UPDATE_SCHEDULE_PAGE_INDEX":
      return {
        ...state,
        pageIndex: action.payload.pageIndex,
      }
    default:
      return state
  }
}

export default scheduleReducer
