import { TransactionModel } from "../../models/Transaction"

export interface transactionStateInterface {
  transactions: TransactionModel[]
  lastCursor: string | Date
}

const initState: transactionStateInterface = {
  transactions: [],
  lastCursor: "",
}

const transactionReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload.transactions,
        lastCursor: action.payload.lastCursor,
      }
    case "UPDATE_TRANSACTIONS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      }
    default:
      return state
  }
}

export default transactionReducer
