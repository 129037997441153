import Ngo from "../../models/Ngos"

export interface ngoStateInterface {
  ngos: Ngo[]
  loading: boolean
  lastCursor: string | number
}

const initState: ngoStateInterface = {
  ngos: [],
  loading: false,
  lastCursor: "",
}

const ngoReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_NGO_LIST":
      return {
        ...state,
        ngos: action.payload.ngos,
        lastCursor: action.payload.lastCursor,
      }
    case "UPDATE_NGO_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      }
    default:
      return state
  }
}

export default ngoReducer
